import React, { Component } from 'react';
import { bool, func, node, object, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';

import IconPlus from '../IconPlus/IconPlus';
import FilterForm from '../FilterForm/FilterForm';

import css from './FilterPlain.module.css';
import { getQueryParamNames } from '../../../util/search';

class FilterPlainComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: true };

    this.handleChange = this.handleChange.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.toggleIsOpen = this.toggleIsOpen.bind(this);
  }

  handleChange(values) {
    const { onSubmit } = this.props;
    onSubmit(values);
  }

  handleClear() {
    const { onSubmit, onClear } = this.props;

    if (onClear) {
      onClear();
    }

    onSubmit(null);
  }

  toggleIsOpen() {
    this.setState(prevState => ({ isOpen: !prevState.isOpen }));
  }
  componentDidUpdate(prevProps) {
    const { hasInitialValues, queryParamName, search } = this.props;
    const isCategorySelected = search?.pub_category;
    const color = queryParamName === 'pub_colour';
    const condition = queryParamName === 'pub_condition';
    const materialFilter = queryParamName === 'pub_material';
    const size = queryParamName === 'pub_size';

    // if ((color || condition) && isCategorySelected) {
    //   if (!this.state.isOpen) {
    //     this.setState({ isOpen: true });
    //   }
    // } else if ((color || condition || materialFilter || size) && this.state.isOpen) {
    //   this.setState({ isOpen: false });
    // }
  }
  componentDidMount() {
    const { hasInitialValues, queryParamName, search } = this.props;
    const materialFilter = queryParamName === 'pub_material';
    const size = queryParamName === 'pub_size';
    const color = queryParamName === 'pub_colour';
    const condition = queryParamName === 'pub_condition';
    const isCategorySelected = search?.pub_category;
    if (isCategorySelected) {
      this.setState({ isOpen: true });
    }
    if (materialFilter || size || color || condition) {
      this.setState({ isOpen: false });
    }
    if (hasInitialValues) {
      this.setState({ isOpen: true });
    }
  }

  render() {
    const {
      rootClassName,
      className,
      plainClassName,
      id,
      label,
      labelSelection,
      labelSelectionSeparator,
      isSelected,
      children,
      initialValues,
      keepDirtyOnReinitialize,
      contentPlacementOffset,
      queryParamName,
    } = this.props;
    const classes = classNames(rootClassName || css.root, className);
    const notCategory = queryParamName !== 'pub_department';
    const initialValue =
      initialValues && initialValues[queryParamName] ? initialValues[queryParamName] : null;

    const showClear = notCategory && isSelected;
    return (
      <div className={classes}>
        <div className={css.filterHeader}>
          <button className={css.labelButton} onClick={this.toggleIsOpen}>
            <span className={css.labelButtonContent}>
              <span className={css.labelWrapper}>
                <span className={css.label}>
                  {label}
                  {labelSelection && labelSelectionSeparator ? labelSelectionSeparator : null}
                  {labelSelection ? (
                    <span className={css.labelSelected}>{labelSelection}</span>
                  ) : null}
                </span>
              </span>
              <span className={css.openSign}>
                <IconPlus isOpen={this.state.isOpen} isSelected={isSelected} />
              </span>
            </span>
          </button>
        </div>
        <div
          id={id}
          className={classNames(plainClassName, css.plain, { [css.isOpen]: this.state.isOpen })}
          ref={node => {
            this.filterContent = node;
          }}
        >
          <FilterForm
            id={`${id}.form`}
            liveEdit
            contentPlacementOffset={contentPlacementOffset}
            onChange={this.handleChange}
            initialValues={initialValues}
            keepDirtyOnReinitialize={keepDirtyOnReinitialize}
            showClear={showClear}
          >
            {children}
          </FilterForm>
          {showClear ? (
            <button className={css.clearButton} onClick={this.handleClear}>
              <FormattedMessage id={'FilterPlain.clear'} />
            </button>
          ) : null}
        </div>
      </div>
    );
  }
}

FilterPlainComponent.defaultProps = {
  rootClassName: null,
  className: null,
  plainClassName: null,
  initialValues: null,
  keepDirtyOnReinitialize: false,
  labelSelection: null,
  labelSelectionSeparator: null,
};

FilterPlainComponent.propTypes = {
  rootClassName: string,
  className: string,
  plainClassName: string,
  id: string.isRequired,
  onSubmit: func.isRequired,
  label: node.isRequired,
  labelSelection: node,
  labelSelectionSeparator: node,
  isSelected: bool.isRequired,
  children: node.isRequired,
  initialValues: object,
  keepDirtyOnReinitialize: bool,

  // form injectIntl
  intl: intlShape.isRequired,
};

const FilterPlain = injectIntl(FilterPlainComponent);

export default FilterPlain;
